import React from 'react'

export type NoteProps = {}

export const Note: React.FC<NoteProps> = React.memo(({}) => {
    return (
        <svg
            xmlns='http://www.w3.org/2000/svg'
            width='100%'
            viewBox='0 0 85 77'
            version='1.1'
        >
            <g stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
                <g transform='translate(0.000000, -2.000000)' fillRule='nonzero'>
                    <path
                        d='M63.648,66.7413333 C63.2092612,69.5363969 63.8989526,72.3912633 65.565319,74.677766 C67.2316854,76.9642688 69.7381957,78.4950669 72.5333333,78.9333333 L62.0053333,77.2373333 L63.648,66.7413333 Z'
                        fill='#B8F0F9'
                    />
                    <path
                        d='M11.4192931,4.57082494 L51.6219598,4.57082494 L51.6219598,7.48282494 C51.6219598,13.3738623 46.8463304,18.1494916 40.9552931,18.1494916 L0.75262642,18.1494916 L0.75262642,15.2374916 C0.75262642,9.34645427 5.52825575,4.57082494 11.4192931,4.57082494 Z'
                        fill='#7D8FE3'
                        transform='translate(26.187293, 11.360158) rotate(-170.850000) translate(-26.187293, -11.360158) '
                    />
                    <path
                        d='M16.6752552,7.46498337 L56.8672552,7.46498337 C62.7582925,7.46498337 67.5339219,12.2406127 67.5339219,18.13165 L67.5339219,73.5983167 L27.3419219,73.5983167 C21.4508845,73.5983167 16.6752552,68.8226874 16.6752552,62.93165 L16.6752552,7.46498337 Z'
                        fill='#A1B3F5'
                        transform='translate(42.104589, 40.531650) rotate(9.150000) translate(-42.104589, -40.531650) '
                    />
                    <path
                        d='M20.5546667,14.3573333 C21.4900175,8.54683632 17.5425364,3.07668721 11.7333333,2.13333333 L22.2613333,3.82933333 L20.5546667,14.3573333 Z'
                        fill='#A1B3F5'
                    />
                    <path
                        d='M33.984,58.9973333 L33.5146667,61.8666667 C32.5914512,67.6737919 27.1428733,71.6385626 21.3333333,70.7306667 L31.8613333,72.4266667 L71.552,78.8266667 C77.3579811,79.7349432 82.8049271,75.7761296 83.7333333,69.9733333 L84.2026667,67.0826667 L33.984,58.9973333 Z'
                        fill='#7D8FE3'
                    />
                    <path
                        d='M28.6181761,24.9182189 L59.9081761,24.9182189 C61.0320756,24.9182189 61.9431761,25.8293194 61.9431761,26.9532189 L61.9431761,27.2245522 C61.9431761,28.3484517 61.0320756,29.2595522 59.9081761,29.2595522 L28.6181761,29.2595522 C27.4942767,29.2595522 26.5831761,28.3484517 26.5831761,27.2245522 L26.5831761,26.9532189 C26.5831761,25.8293194 27.4942767,24.9182189 28.6181761,24.9182189 Z'
                        fill='#EBEDFC'
                        transform='translate(44.263176, 27.088886) rotate(9.150000) translate(-44.263176, -27.088886) '
                    />
                    <path
                        d='M26.6593942,37.0613529 L57.9493942,37.0613529 C59.0732937,37.0613529 59.9843942,37.9724535 59.9843942,39.0963529 L59.9843942,39.3676863 C59.9843942,40.4915857 59.0732937,41.4026863 57.9493942,41.4026863 L26.6593942,41.4026863 C25.5354947,41.4026863 24.6243942,40.4915857 24.6243942,39.3676863 L24.6243942,39.0963529 C24.6243942,37.9724535 25.5354947,37.0613529 26.6593942,37.0613529 Z'
                        fill='#EBEDFC'
                        transform='translate(42.304394, 39.232020) rotate(9.150000) translate(-42.304394, -39.232020) '
                    />
                    <path
                        d='M24.7095827,49.2043512 L55.9995827,49.2043512 C57.1234822,49.2043512 58.0345827,50.1154518 58.0345827,51.2393512 L58.0345827,51.5106846 C58.0345827,52.634584 57.1234822,53.5456846 55.9995827,53.5456846 L24.7095827,53.5456846 C23.5856833,53.5456846 22.6745827,52.634584 22.6745827,51.5106846 L22.6745827,51.2393512 C22.6745827,50.1154518 23.5856833,49.2043512 24.7095827,49.2043512 Z'
                        fill='#EBEDFC'
                        transform='translate(40.354583, 51.375018) rotate(9.150000) translate(-40.354583, -51.375018) '
                    />
                </g>
            </g>
        </svg>
    )
})

Note.displayName = 'Note'

export default Note
