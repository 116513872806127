import React from 'react'

export type PaletteProps = {}

export const Palette: React.FC<PaletteProps> = React.memo(({}) => {
    return (
        <svg
            xmlns='http://www.w3.org/2000/svg'
            width='100%'
            viewBox='0 0 68 79'
            version='1.1'
        >
            <g stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
                <g transform='translate(0.000000, -1.000000)' fillRule='nonzero'>
                    <path
                        d='M49.0746269,33.158209 C49.1944563,28.243957 50.5407297,23.4375965 52.9910448,19.1761194 C54.9244076,15.7278564 55.0257026,11.5462011 53.2615685,8.00837115 C51.4974344,4.47054122 48.0965936,2.03522121 44.1791045,1.50447761 C41.9431405,1.21077831 39.6863236,1.10692479 37.4328358,1.19402985 C22.1472706,1.89390284 8.66411212,11.4188174 2.89629116,25.5916988 C-2.8715298,39.7645802 0.12907105,55.9977547 10.5822043,67.1722941 C21.0353376,78.3468335 37.0324783,82.422499 51.558209,77.6119403 C61.5164179,74.3044776 64.2149254,61.7313433 57.0507463,54.0776119 C51.7390869,48.4280842 48.872598,40.9099877 49.0746269,33.158209 L49.0746269,33.158209 Z'
                        fill='#F5A475'
                    />
                    <circle
                        fill='#FFEBDE'
                        cx='43.2716418'
                        cy='65.6477612'
                        r='4.48955224'
                    />
                    <circle
                        fill='#FFEBDE'
                        cx='14.1014925'
                        cy='41.8626866'
                        r='4.48955224'
                    />
                    <circle
                        fill='#FFEBDE'
                        cx='24.7761194'
                        cy='60.8358209'
                        r='4.48955224'
                    />
                    <circle
                        fill='#FFEBDE'
                        cx='21.0865672'
                        cy='22.6507463'
                        r='4.48955224'
                    />
                    <circle
                        fill='#FFEBDE'
                        cx='36.3343284'
                        cy='15.9880597'
                        r='4.48955224'
                    />
                    <path
                        d='M50.9731343,46.7940299 L44.7164179,40.5970149 L63.8208955,24.358209 C64.7727147,23.5373344 66.1979072,23.5947598 67.080597,24.4895522 L67.080597,24.4895522 C67.9753895,25.372242 68.0328148,26.7974345 67.2119403,27.7492537 L50.9731343,46.7940299 Z'
                        fill='#F57939'
                    />
                    <path
                        d='M43.6776119,41.7910448 L49.9343284,48.0597015 C49.9343284,48.0597015 47.0328358,55.4865672 41.5761194,56.4179104 C36.119403,57.3492537 34.758209,60.9910448 34.758209,60.9910448 C34.758209,60.9910448 29.2537313,48.7522388 43.6776119,41.7910448 Z'
                        fill='#F57939'
                    />
                </g>
            </g>
        </svg>
    )
})

Palette.displayName = 'Palette'

export default Palette
