import React from 'react'

export type MessageProps = {}

export const Message: React.FC<MessageProps> = React.memo(({}) => {
    return (
        <svg
            width='100%'
            viewBox='0 0 51 45'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
        >
            <path
                d='M36.69 2.2627H4.2209C3.01412 2.2627 2.03583 3.24099 2.03583 4.44777V30.4158C2.03583 31.6226 3.01412 32.6009 4.2209 32.6009H36.69C37.8968 32.6009 38.8751 31.6226 38.8751 30.4158V4.44777C38.8751 3.24099 37.8968 2.2627 36.69 2.2627Z'
                fill='white'
                stroke='#111111'
                strokeWidth='4'
                strokeLinecap='round'
                strokeLinejoin='round'
            />
            <path
                d='M32.3741 9.48584L20.8166 21.0433L9.25922 9.48584'
                stroke='#111111'
                strokeWidth='4'
                strokeLinecap='round'
                strokeLinejoin='round'
            />
            <path
                d='M12.1486 22.4878L8.53687 26.0995'
                stroke='#111111'
                strokeWidth='4'
                strokeLinecap='round'
                strokeLinejoin='round'
            />
            <path
                d='M28.7624 22.4878L32.3741 26.0995'
                stroke='#111111'
                strokeWidth='4'
                strokeLinecap='round'
                strokeLinejoin='round'
            />
        </svg>
    )
})

Message.displayName = 'Message'

export default Message
