import React from 'react'

export type HelmetProps = {}

export const Helmet: React.FC<HelmetProps> = React.memo(({}) => {
    return (
        <svg
            xmlns='http://www.w3.org/2000/svg'
            width='100%'
            viewBox='0 0 80 56'
            version='1.1'
        >
            <g stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
                <g fillRule='nonzero'>
                    <path
                        d='M78.2457831,40.0192771 C79.5121739,41.3966496 80.0526147,43.2917683 79.7032131,45.1299247 C79.3538115,46.9680811 78.1557276,48.5327364 76.4722892,49.3493976 C70.7662651,52.086747 60.086747,55.1325301 40.539759,55.5084337 C20.2409639,55.9036145 9.50361446,52.5686747 3.97108434,49.513253 C2.39619148,48.627475 1.31319067,47.0697521 1.03138178,45.2849625 C0.749572901,43.500173 1.29994013,41.6845498 2.5253012,40.3566265 L3.73012048,39.0457831 L77.3590361,39.0457831 L78.2457831,40.0192771 Z'
                        fill='#0AC275'
                    />
                    <path
                        d='M77.3590361,39.0457831 C77.0337965,18.9459476 60.647045,2.82220844 40.5445783,2.82220844 C20.4421116,2.82220844 4.05536011,18.9459476 3.73012048,39.0457831 C3.73012048,39.0457831 8.54939759,49.060241 40.539759,48.4240964 C72.5301205,47.7879518 77.3590361,39.0457831 77.3590361,39.0457831 Z'
                        fill='#68E3B2'
                    />
                    <path
                        d='M50.9012048,1.56144578 C44.0480353,-0.553937003 36.7110014,-0.516932247 29.8795181,1.66746988 L29.8795181,28.260241 L50.9012048,28.260241 L50.9012048,1.56144578 Z'
                        fill='#68E3B2'
                    />
                    <path
                        d='M29.8795181,3.85542169 C28.6939759,4.21204819 27.5373494,4.62650602 26.4192771,5.08915663 L29.8795181,28.260241 L29.8795181,3.85542169 Z'
                        fill='#0AC275'
                    />
                    <path
                        d='M50.9012048,3.72048193 C52.086747,4.07710843 53.2433735,4.49156627 54.3614458,4.95421687 L50.9012048,28.1638554 L50.9012048,3.72048193 Z'
                        fill='#0AC275'
                    />
                    <rect
                        fill='#E0EDE8'
                        x='37.773494'
                        y='17.2240964'
                        width='5.53253012'
                        height='16.2409639'
                        rx='2.76626506'
                    />
                </g>
            </g>
        </svg>
    )
})

Helmet.displayName = 'Helmet'

export default Helmet
