import React from 'react'

export type BathroomProps = {}

export const Bathroom: React.FC<BathroomProps> = React.memo(({}) => {
    return (
        <svg
            xmlns='http://www.w3.org/2000/svg'
            width='100%'
            viewBox='0 0 75 80'
            version='1.1'
        >
            <g stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
                <g>
                    <rect
                        fill='#8DCCE3'
                        fillRule='nonzero'
                        x='0'
                        y='0'
                        width='37.4842105'
                        height='31.5052632'
                    />
                    <polyline
                        stroke='#DBEAED'
                        strokeWidth='2.23'
                        strokeLinecap='round'
                        strokeLinejoin='round'
                        points='18.7368421 34 18.7368421 26.3894737 24.5789474 26.3894737'
                    />
                    <polygon
                        fill='#2EB3D6'
                        fillRule='nonzero'
                        points='72.0631579 50.1473684 47.0105263 50.1473684 45.0736842 33.6736842 74.0105263 33.6736842'
                    />
                    <path
                        d='M47.8105263,24.9263158 L71.2736842,24.9263158 C72.7852004,24.9263158 74.0105263,26.1516417 74.0105263,27.6631579 L74.0105263,33.6842105 L45.0736842,33.6842105 L45.0736842,27.6631579 C45.0736842,26.1516417 46.2990102,24.9263158 47.8105263,24.9263158 Z'
                        id='Path'
                        fill='#2EB3D6'
                        fillRule='nonzero'
                    />
                    <rect
                        fill='#2EB3D6'
                        fillRule='nonzero'
                        x='0'
                        y='34'
                        width='37.4842105'
                        height='6.24210526'
                    />
                    <polygon
                        fill='#2EB3D6'
                        fillRule='nonzero'
                        points='22.8210526 42.6421053 14.6526316 42.6421053 11.6210526 79.8105263 25.8631579 79.8105263'
                    />
                    <polygon
                        fill='#2EB3D6'
                        fillRule='nonzero'
                        points='67.3578947 64.3578947 51.7157895 64.3578947 49.4526316 79.8105263 69.6210526 79.8105263'
                    />
                    <path
                        d='M0,40.2421053 C4.96933529,45.2154202 11.7115893,48.0097342 18.7421053,48.0097342 C25.7726212,48.0097342 32.5148752,45.2154202 37.4842105,40.2421053 L0,40.2421053 Z'
                        fill='#2EB3D6'
                        fillRule='nonzero'
                    />
                    <path
                        d='M48.4736842,41.7263158 C48.4736842,33.1368421 53.4210526,26.1684211 59.5368421,26.1684211 C65.6526316,26.1684211 70.6,33.1368421 70.6,41.7263158'
                        fill='#B4D6E3'
                        fillRule='nonzero'
                    />
                    <path
                        d='M67.5052632,52.5052632 C69.5859508,49.3000524 70.6634504,45.5471287 70.6,41.7263158 L48.4947368,41.7263158 C48.428158,45.5460421 49.5019679,49.2988792 51.5789474,52.5052632 L67.5052632,52.5052632 Z'
                        fill='#B4D6E3'
                        fillRule='nonzero'
                    />
                    <path
                        d='M74.0105263,56.8421053 L45.0736842,56.8421053 L45.0736842,52 C45.0736842,50.9593793 45.917274,50.1157895 46.9578947,50.1157895 L72.1157895,50.1157895 C73.1581267,50.1157895 74.0047356,50.9576789 74.0105263,52 L74.0105263,56.8421053 Z'
                        fill='#B4D6E3'
                        fillRule='nonzero'
                    />
                    <path
                        d='M74.0105263,56.8421053 C73.7990738,64.6808346 67.3836861,70.9254987 59.5421053,70.9254987 C51.7005245,70.9254987 45.2851367,64.6808346 45.0736842,56.8421053'
                        fill='#B4D6E3'
                        fillRule='nonzero'
                    />
                    <path
                        d='M48.4736842,41.7263158 C48.4736842,33.1368421 53.4210526,26.1684211 59.5368421,26.1684211 C65.6526316,26.1684211 70.6,33.1368421 70.6,41.7263158'
                        fill='#E0EBED'
                        fillRule='nonzero'
                    />
                    <path
                        d='M67.5052632,52.5052632 C69.5859508,49.3000524 70.6634504,45.5471287 70.6,41.7263158 L48.4947368,41.7263158 C48.428158,45.5460421 49.5019679,49.2988792 51.5789474,52.5052632 L67.5052632,52.5052632 Z'
                        fill='#E0EBED'
                        fillRule='nonzero'
                    />
                    <path
                        d='M74.0105263,56.8421053 L45.0736842,56.8421053 L45.0736842,52 C45.0736842,50.9593793 45.917274,50.1157895 46.9578947,50.1157895 L72.1157895,50.1157895 C73.1581267,50.1157895 74.0047356,50.9576789 74.0105263,52 L74.0105263,56.8421053 Z'
                        fill='#8DCCE3'
                        fillRule='nonzero'
                    />
                    <path
                        d='M74.0105263,56.8421053 C73.7990738,64.6808346 67.3836861,70.9254987 59.5421053,70.9254987 C51.7005245,70.9254987 45.2851367,64.6808346 45.0736842,56.8421053'
                        fill='#2EB3D6'
                        fillRule='nonzero'
                    />
                    <path
                        d='M62.5368421,6.01052632 L56.5368421,6.01052632 C56.5432563,7.66622891 55.9989073,9.27705756 54.9894737,10.5894737 C53.1541295,12.9494008 53.4344877,16.3222856 55.6342726,18.346808 C57.8340575,20.3713303 61.2185741,20.3713303 63.418359,18.346808 C65.6181439,16.3222856 65.8985021,12.9494008 64.0631579,10.5894737 C63.0547361,9.27780423 62.5171064,7.66491495 62.5368421,6.01052632 Z'
                        fill='#8DCCE3'
                        fillRule='nonzero'
                    />
                    <path
                        d='M57.6947368,1.48421053 L61.3789474,1.48421053 C62.018435,1.48421053 62.5368421,2.00261766 62.5368421,2.64210526 L62.5368421,6.01052632 L56.5368421,6.01052632 L56.5368421,2.64210526 C56.5368421,2.00261766 57.0552492,1.48421053 57.6947368,1.48421053 Z'
                        id='Path'
                        fill='#2EB3D6'
                        fillRule='nonzero'
                    />
                    <path
                        d='M60.2210526,0 L59.1684211,0 C58.7779987,0.00272540099 58.4434946,0.280011732 58.3684211,0.663157895 L58.0105263,2.53684211 L61.3684211,2.53684211 L61.0526316,0.663157895 C60.9754338,0.268171458 60.623314,-0.0126329662 60.2210526,-9.11271926e-17 L60.2210526,0 Z'
                        fill='#2EB3D6'
                        fillRule='nonzero'
                    />
                </g>
            </g>
        </svg>
    )
})

Bathroom.displayName = 'Bathroom'

export default Bathroom
