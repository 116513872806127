import React from 'react'
import styled from 'styled-components'
import { FCol, FColG16 } from '../Layout'
import Cash from './Icons/Cash'
import Call from './Icons/Call'
import Message from './Icons/Message'
import Umbrella from './Icons/Umbrella'
import Palette from './Icons/Palette'
import Bathroom from './Icons/Bathroom'
import Helmet from './Icons/Helmet'
import Note from './Icons/Note'

const Wrapper = styled(FColG16)`
    padding: 32px;
    color: #666666;
    font-size: 1.375rem;
    font-weight: 500;
    line-height: 1.33;
    letter-spacing: -0.4px;
`
const IconWrapper = styled.div`
    width: 56px;
    height: 56px;
`

export type InfoSectionProps = {
    color: 'green' | 'purple' | 'blue' | 'orange'
    icon:
        | 'message'
        | 'call'
        | 'cash'
        | 'umbrella'
        | 'palette'
        | 'bathroom'
        | 'helmet'
        | 'note'
        | 'none'
} & React.HTMLAttributes<HTMLDivElement>

export const icons: { [key in InfoSectionProps['icon']]: React.FC } = {
    message: Message,
    call: Call,
    umbrella: Umbrella,
    palette: Palette,
    bathroom: Bathroom,
    helmet: Helmet,
    note: Note,
    cash: Cash,
    none: () => null,
}
export const colors: { [key in InfoSectionProps['color']]: string } = {
    green: '#E0EDE8',
    purple: '#ECECFC',
    orange: 'rgb(255, 235, 222)',
    blue: '#E0EBED',
}

export const InfoSection: React.FC<InfoSectionProps> = React.memo<InfoSectionProps>(
    ({ icon, color, children, ...rest }) => {
        const IconCmp = icons[icon] ?? icons.none
        const cssColor = colors[color] ?? colors.blue

        return (
            <Wrapper {...rest} style={{ backgroundColor: cssColor, ...rest.style }}>
                {icons.none !== IconCmp && (
                    <IconWrapper>
                        <IconCmp />
                    </IconWrapper>
                )}
                <FCol>{children}</FCol>
            </Wrapper>
        )
    },
)

InfoSection.displayName = 'InfoSection'

export default InfoSection
