import styled from 'styled-components'
import { FCol, FRow, FRowG8 } from '../../atoms/Layout'
import { P } from '../../atoms/Text'
import { columnGap } from '../../utils'
import ThinArrow from '../../atoms/Icons/ThinArrow'

export const Wrapper = styled(FRow)`
    width: 100%;
    ${columnGap(100)}
`

export const Column = styled.div`
    flex: 1;
`

export const StepWrapper = styled(FCol)`
    flex-shrink: 0;
    align-items: center;
    margin-right: 16px;
`

export const VLineBg = styled.div`
    margin-top: 8px;
    margin-bottom: 8px;
    min-height: 16px;
    flex: 1;
    width: 2px;
    background-color: rgb(28, 33, 31, 0.3);
`

export const Circle = styled(FRow)<{ isActive: boolean }>`
    align-items: center;
    justify-content: center;
    width: 32px;
    height: 32px;
    padding: 10px;
    border-radius: 50%;
    transition: all 0.2s ease-in-out;
    font-size: 10px;
    color: ${(props) => (props.isActive ? 'white' : 'rgb(164, 172, 185)')};
    background-color: ${(props) => (props.isActive ? 'rgb(28, 33, 31)' : 'white')};
    border: 1px solid
        ${(props) => (props.isActive ? 'rgb(28, 33, 31)' : 'rgba(164, 172, 185)')};
`

export const VLineFg = styled.div`
    transition: height 0.2s ease-in-out;
    width: 100%;
    background-color: rgb(28, 33, 31);
`

export const AnimatedThinArrow = styled(ThinArrow)`
    transition: opacity 0.2s ease-in-out;
`

export const StepTitle = styled(FRowG8)`
    font-size: 1.5rem;
    line-height: 32px;
    letter-spacing: -0.4px;
    font-weight: bold;
    align-items: center;
`

export const StepDescription = styled(P)`
    font-size: 1rem;
    margin-bottom: 16px;
`

export const Img = styled.img`
    width: 90%;
`

export const RightWrapper = styled(FRow)`
    flex: 1;
`

export const TabletImgWrapper = styled.div`
    margin-bottom: 16px;
`

export const ImageColumn = styled(Column)`
    display: flex;
    align-items: center;
`
