import React from 'react'

export type UmbrellaProps = {}

export const Umbrella: React.FC<UmbrellaProps> = React.memo(({}) => {
    return (
        <svg
            xmlns='http://www.w3.org/2000/svg'
            width='100%'
            viewBox='0 0 76 80'
            version='1.1'
        >
            <g stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
                <g fillRule='nonzero'>
                    <rect
                        fill='#8DCCE3'
                        x='0'
                        y='17.0352941'
                        width='75.3764706'
                        height='62.5058824'
                        rx='7.32'
                    />
                    <path
                        d='M64.6235294,58.0823529 L24.2,58.0823529 L10.2705882,44.1529412 C9.33421875,43.4092873 7.9889956,43.4861904 7.14347536,44.3317106 C6.29795511,45.1772309 6.22105196,46.522454 6.96470588,47.4588235 L21.5647059,62.0705882 C22.0087502,62.5110271 22.6098753,62.7565571 23.2352941,62.7529412 L25.1882353,62.7529412 L20.6470588,71.3058824 L23.0823529,71.3058824 L27.4941176,62.9882353 C27.4941176,62.9058824 27.4941176,62.8235294 27.5647059,62.7529412 L55.4705882,62.7529412 C55.4848037,62.8338706 55.5084962,62.9128453 55.5411765,62.9882353 L60,71.3176471 L62.3529412,71.3176471 L57.8235294,62.7647059 L64.6,62.7647059 C65.8994935,62.7647059 66.9529412,61.7112582 66.9529412,60.4117647 C66.9529412,59.1122712 65.8994935,58.0588235 64.6,58.0588235 L64.6235294,58.0823529 Z'
                        id='Path'
                        fill='#E0EBED'
                    />
                    <path
                        d='M36.1882353,1.01176471 C27.907738,3.12395296 20.8059094,8.43996917 16.4459349,15.7897036 C12.0859604,23.139438 10.8252059,31.9204685 12.9411765,40.2 C13.4292413,36.6998681 15.9847694,33.842055 19.4088026,32.9673397 C22.8328358,32.0926243 26.445924,33.3745815 28.5529412,36.2117647 C28.9539276,33.1759787 30.9244191,30.5759022 33.7387329,29.3690781 C36.5530467,28.162254 39.7949717,28.5271605 42.2705882,30.3294118 L52.7176471,71.2705882 L55.5529412,71.2705882 L44.9647059,29.6470588 C46.2869089,26.9107088 48.9419575,25.0633698 51.967276,24.7747861 C54.9925944,24.4862025 57.9490169,25.7982661 59.7647059,28.2352941 C60.2527707,24.7351622 62.8082988,21.8773491 66.232332,21.0026338 C69.6563653,20.1279185 73.2694534,21.4098756 75.3764706,24.2470588 C70.9670503,7.01237663 53.4252825,-3.38840136 36.1882353,1.01176471 Z'
                        id='Path'
                        fill='#2EB3D6'
                    />
                </g>
            </g>
        </svg>
    )
})

Umbrella.displayName = 'Umbrella'

export default Umbrella
