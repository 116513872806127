import React from 'react'

export type CashProps = {}

export const Cash: React.FC<CashProps> = React.memo(({}) => {
    return (
        <svg
            width='100%'
            viewBox='0 0 64 45'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
        >
            <g clipPath='url(#cash-icon-clip)'>
                <path
                    d='M12.1365 0.149649L55.5804 9.22599C58.7839 9.89527 60.8378 13.0327 60.168 16.2335L55.1873 40.0346C54.5174 43.2355 51.3775 45.2878 48.1739 44.6185L4.73007 35.5421C1.52655 34.8729 -0.527409 31.7355 0.142425 28.5346L5.12316 4.73352C5.79299 1.53264 8.93296 -0.519634 12.1365 0.149649Z'
                    fill='#68E3B2'
                />
                <path
                    d='M40.3059 23.9319C41.1624 18.3327 37.3139 13.0999 31.7101 12.2441C26.1063 11.3883 20.8692 15.2336 20.0127 20.8328C19.1562 26.432 23.0046 31.6648 28.6084 32.5206C34.2122 33.3764 39.4494 29.5311 40.3059 23.9319Z'
                    fill='#E0EDE8'
                />
                <path
                    d='M28.1212 29.2392L28.4803 27.5167C27.5615 27.3225 26.7155 26.8749 26.0381 26.2249L26.6846 25.177C27.3171 25.8007 28.1145 26.2314 28.9832 26.4186C30.1971 26.6698 31.138 26.1459 31.3607 25.177C31.5834 24.2081 31.0087 23.5263 29.8595 22.7512C28.2649 21.7105 27.3526 20.7057 27.6615 19.2272C27.992 17.8002 29.3448 16.8507 30.8004 17.0239L31.138 15.3229L32.1939 15.5454L31.8563 17.1746C32.6317 17.3464 33.3509 17.7119 33.9466 18.2368L33.2929 19.2703C32.7263 18.7467 32.0249 18.3913 31.2673 18.244C29.96 17.9713 29.2992 18.6459 29.1627 19.3277C28.976 20.2105 29.5147 20.7631 30.8651 21.7033C32.4669 22.7871 33.1636 23.8564 32.8476 25.3636C32.5058 26.8642 31.0877 27.8666 29.5578 27.689L29.1843 29.4617L28.1212 29.2392Z'
                    fill='#0AC275'
                />
                <path
                    d='M59.6753 0.330139H47.1493C45.0188 0.330139 43.2916 2.0559 43.2916 4.18474V16.7004C43.2916 18.8293 45.0188 20.555 47.1493 20.555H59.6753C61.8059 20.555 63.5331 18.8293 63.5331 16.7004V4.18474C63.5331 2.0559 61.8059 0.330139 59.6753 0.330139Z'
                    fill='#0AC275'
                />
                <path
                    d='M46.7538 9.90428L52.026 15.1722L60.0781 7.12677'
                    stroke='#DBEDE6'
                    strokeWidth='3'
                    strokeLinecap='round'
                    strokeLinejoin='round'
                />
            </g>
            <defs>
                <clipPath id='cash-icon-clip'>
                    <rect width='64' height='45' fill='white' />
                </clipPath>
            </defs>
        </svg>
    )
})

Cash.displayName = 'Cash'

export default Cash
