import React from 'react'
import styled from 'styled-components'

const Svg = styled.svg.attrs({
    viewBox: '0 0 1024 1024',
})`
    display: inline-block;
    stroke: currentColor;
    fill: currentColor;
    width: 14px;
    height: 14px;
    flex-shrink: 0;
`

export const Checkmark: React.FC<React.HTMLAttributes<SVGElement>> = (props) => {
    return (
        <Svg {...props}>
            <path
                d={
                    'M1024 267.832l-162.909-158.118-465.455 451.765-232.727-225.882-162.909 158.118 395.636 384z'
                }
            />
        </Svg>
    )
}
