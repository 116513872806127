import React from 'react'

export type CallProps = {}

export const Call: React.FC<CallProps> = React.memo(({}) => {
    return (
        <svg
            width='100%'
            viewBox='0 0 48 48'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
        >
            <path
                fillRule='evenodd'
                clipRule='evenodd'
                d='M32.8955 36.1309C24.5979 36.1309 17.0867 32.7684 11.6499 27.3317C6.21317 21.8949 2.85074 14.3837 2.85074 6.08609C2.85074 5.02701 3.27968 4.07298 3.97012 3.38254C4.66336 2.68929 5.62102 2.26221 6.67462 2.26221H12.4104C13.4716 2.26221 14.4259 2.69041 15.116 3.38053C15.8061 4.07065 16.2343 5.02496 16.2343 6.08609C16.2343 7.88727 16.5228 9.63077 17.0582 11.2512C17.2783 11.9412 17.3039 12.659 17.1394 13.3406C16.9799 14.0013 16.6435 14.6286 16.1304 15.1416L13.6826 17.6006C15.5779 20.7566 18.2312 23.4096 21.3915 25.3052L23.8365 22.8547C24.353 22.3381 24.9803 22.0017 25.641 21.8422C26.3226 21.6777 27.0404 21.7033 27.7087 21.9164C29.3508 22.4588 31.0943 22.7473 32.8955 22.7473C33.9566 22.7473 34.911 23.1755 35.6011 23.8656C36.2912 24.5557 36.7194 25.51 36.7194 26.5712V32.307C36.7194 33.3681 36.2912 34.3224 35.6011 35.0125C34.911 35.7027 33.9566 36.1309 32.8955 36.1309Z'
                fill='white'
                stroke='#111111'
                strokeWidth='4'
                strokeLinecap='round'
                strokeLinejoin='round'
            />
        </svg>
    )
})

Call.displayName = 'Call'

export default Call
