import { Collapse, Fade } from '@material-ui/core'
import React from 'react'
import { Checkmark } from '../../atoms/Icons/Checkmark'
import { FCol, FRow } from '../../atoms/Layout'
import { useRenderBreakpoints } from '../../utils/hooks/render'
import {
    AnimatedThinArrow,
    Circle,
    Column,
    ImageColumn,
    Img,
    RightWrapper,
    StepDescription,
    StepTitle,
    StepWrapper,
    TabletImgWrapper,
    VLineBg,
    VLineFg,
    Wrapper,
} from './styled'

export type Step = {
    title: string
    image: string
    description: string
}

export type StepperProps = {
    steps: Step[]
}

export const Stepper: React.FC<StepperProps> = React.memo(({ steps }) => {
    const [activeStep, setActiveStep] = React.useState(0)
    const { onTablet } = useRenderBreakpoints()
    const getImage = (img?: string) => {
        return <Img src={img ?? steps[activeStep].image} />
    }

    return (
        <Wrapper>
            <Column>
                {steps.map((step, index) => {
                    return (
                        <FRow
                            key={index}
                            onClick={() => {
                                setActiveStep(index)
                            }}
                            onMouseEnter={() => {
                                setActiveStep(index)
                            }}
                        >
                            <StepWrapper>
                                <Circle isActive={activeStep >= index}>
                                    <Checkmark />
                                </Circle>
                                <VLineBg>
                                    <VLineFg
                                        style={{
                                            height: activeStep >= index ? '100%' : 0,
                                        }}
                                    />
                                </VLineBg>
                            </StepWrapper>
                            <RightWrapper>
                                <FCol>
                                    <StepTitle>
                                        <div>{step.title}</div>
                                        {onTablet(
                                            () => (
                                                <AnimatedThinArrow
                                                    style={{
                                                        opacity:
                                                            activeStep === index ? 1 : 0,
                                                    }}
                                                />
                                            ),
                                            { mode: 'hide' },
                                        )}
                                    </StepTitle>
                                    <StepDescription>{step.description}</StepDescription>
                                    {onTablet(() => (
                                        <Collapse in={activeStep === index} timeout={400}>
                                            <TabletImgWrapper>
                                                {getImage(step.image)}
                                            </TabletImgWrapper>
                                        </Collapse>
                                    ))}
                                </FCol>
                            </RightWrapper>
                        </FRow>
                    )
                })}
            </Column>
            {onTablet(
                () => (
                    <ImageColumn>
                        <Fade key={activeStep} in={true} timeout={500} appear={true}>
                            {getImage()}
                        </Fade>
                    </ImageColumn>
                ),
                { mode: 'hide' },
            )}
        </Wrapper>
    )
})

Stepper.displayName = 'Stepper'

export default Stepper
